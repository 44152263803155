const dropdowns = document.querySelectorAll(".dropdown");

for (let i = 0; i < dropdowns.length; i++) {
  const dropdown = dropdowns[i];

  dropdown.addEventListener("mouseover", function (e) {
    toggleDropdown(dropdown, true);
  });
  dropdown.addEventListener("mouseleave", function (e) {
    toggleDropdown(dropdown, false);
  });
}

const toggleDropdown = (ele, dropdown) => {
  const subMenu = ele.querySelector(".sub-menu");
  if (!subMenu) return;

  if (dropdown) {
    ele.classList.add("hover");
    subMenu.style.display = "block";
  } else {
    ele.classList.remove("hover");
    subMenu.style.display = "none";
  }
};
